import React from "react";
import "./styles.css";

export default function Footer() {
    return(
        <>
        <div class="footer-filler"></div>
        <footer>
            <img id="footer-mocap-icon" src="./img/mocap_iso.png"></img>
        </footer>
        </>
    );
}
